import { Routes, Route } from "react-router-dom";
import DashboardView from "../pages/admin/Dashboard";
import UserManager from "../pages/admin/UserManager";
import SansthaManager from "../pages/admin/SansthaManager";
import MembersManager from "../pages/admin/MembersManager";
import CandidateManager from "../pages/admin/CandidateManager";
import CandidateSearch from "../pages/admin/CandidateSearch";
import AdvanceSearch from "../pages/admin/AdvanceSearch";

import GotraMaster from "../pages/admin/GotraMaster";
import QualificationMaster from "../pages/admin/QualificationMaster";
import PhotoGalleryMaster from "../pages/admin/PhotoGalleryMaster";
import VideoGalleryMaster from "../pages/admin/VideoGalleryMaster";
import BannerMaster from "../pages/admin/BannerMaster";

import HomePageCms from "../pages/admin/HomePageCms";
import AboutPageCms from "../pages/admin/AboutPageCms";
import ContactPageCms from "../pages/admin/ContactPageCms";

import Leads from "../pages/admin/LeadsContainer";
import ContactLeads from "../pages/admin/ContactLeadsContainer";

const AdminRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<DashboardView />} />
            <Route path="/dashboard" element={<DashboardView />} />
            <Route path="/user-manager" element={<UserManager />} />
            <Route path="/sanstha-manager" element={<SansthaManager />} />
            <Route path="/members-manager" element={<MembersManager />} />
            <Route path="/candidate-manager" element={<CandidateManager />} />
            <Route path="/candidate-search" element={<CandidateSearch />} />
            <Route path="/advance-search" element={<AdvanceSearch />} />

            {/* master routes */}
            <Route path="/gotra-master" element={<GotraMaster />} />
            <Route path="/qualification-master" element={<QualificationMaster />} />
            <Route path="/photo-gallery-master" element={<PhotoGalleryMaster />} />
            <Route path="/video-gallery-master" element={<VideoGalleryMaster />} />
            <Route path="/banner-master" element={<BannerMaster />} />

            {/* cms routes */}
            <Route path="/home-page-cms" element={<HomePageCms />} />
            <Route path="/about-page-cms" element={<AboutPageCms />} />
            <Route path="/contact-page-cms" element={<ContactPageCms />} />

            {/* leads */}
            <Route path="/leads" element={<Leads />} />
            <Route path="/contact-leads" element={<ContactLeads />} />
        </Routes>
    );
};

export default AdminRoutes;