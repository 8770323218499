import React from "react";
import Stack from "@mui/material/Stack";

const WelcomeImage = () => {
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                height: "100vh",
                width: "auto",
                backgroundColor: "primary.main",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <img
                src="assets/images/logo.png"
                alt="edubridgeindia-logo"
                width={240}
            />
        </Stack>
    );
};

export default WelcomeImage;
