import React, { Fragment, useEffect, useState } from "react";
import { Formik } from "formik";
import { MembersValidation } from "./MembersValidation";
import { Box, Card, CardContent, Drawer, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined"
import IconButtonIcons from "../../Common/IconButtonIcons";
import Input from "../../Common/Input";
import Button from "../../Common/Button";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice";
import { useAddNewMembersMutation, useUpdateMembersMutation } from "../../../store/members/membersApis";
import BasicSelect from "../../Common/Select";
import AutoCompleteServerSide from "../../Common/AutoCompleteServerSide";
import { useGetGotraListQuery, useGetQualificationListQuery } from "../../../store/masters/mastersApis";
import { memberStatus, workStatus } from "../../../helpers/sampleData";
import { FileUploader } from "react-drag-drop-files";
import { baseUrl, fileTypes } from "../../../helpers/constants";
import { CommonFileUploadToServer } from "../../Common/CommonFileUploadToServer";
import AutoCompleteSelect from "../../Common/AutoCompleteSelect";
import { useGetDropdownSansthaListQuery } from "../../../store/sanstha/sansthaApis";
import StyledTextarea from "../../Common/StyledTextarea";
import DatePickerComponent from "../../Common/DatePicker";
import { FamilyMemberAdd } from "./FamilyMemberAdd";
import CommonModal from "../../Common/CommonModal";
import { format, parseISO } from "date-fns";
import { Add, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { isAuth } from "../../../helpers/cookies";
import { handleDateTimeDefault } from "../../../helpers/utils";

const initialFamilyData = {
    name: "",
    gender: "",
    mobile: "",
    dob: "",
    qualification: "",
    married: "",
    marriage_date: "",
    blood_group: "",
    note: "",
    relation: "Wife",
}

export const MembersAddDrawer = ({ initialValues, show, close, formType, getSansthaList }) => {
    const dispatch = useDispatch();
    const [addNewMembers, { isLoading: addMembersLoading }] = useAddNewMembersMutation();
    const [updateMembers, { isLoading: updateMembersLoading }] = useUpdateMembersMutation();

    const [showModal, setShowModal] = useState(false);
    const [familyMemberInitalval, setFamilyMemberInitalval] = useState(initialFamilyData);
    const [familyEditKey, setFamilyEditKey] = useState(false);
    const [familyMemberData, setFamilyMemberData] = useState([]);

    useEffect(() => {
        setFamilyMemberData(initialValues?.family_details || []);
    }, [initialValues.family_details]);

    const { data: sansthaList } = useGetDropdownSansthaListQuery({}, { skip: !show });

    const { data: gotraData } = useGetGotraListQuery({ pageNo: 0, limit: 1000, sortBy: -1, sortField: "createdAt", active: true }, { skip: !show });
    const { data: qualificationData } = useGetQualificationListQuery({ pageNo: 0, limit: 1000, sortBy: -1, sortField: "createdAt", active: true }, { skip: !show });
    const disabled = formType === 'View' ? true : false;
    const onFormSubmit = async (values, { resetForm }) => {
        try {
            let payload = { ...values };
            payload.family_details = familyMemberData;

            if (formType === "Edit") {
                await updateMembers(payload).unwrap();
                dispatch(setSnackBar({
                    open: true,
                    message: "Members updated successfully",
                    severity: "success",
                }))
            } else {
                await addNewMembers(payload).unwrap();
                dispatch(setSnackBar({
                    open: true,
                    message: "Members created successfully",
                    severity: "success",
                }))
            }
            resetForm();
            close();
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    };

    const handleAddFamilyMember = () => {
        setShowModal(true);
    }

    const handleDeleteFamilyMember = (data, key) => {
        let oldData = JSON.parse(JSON.stringify(familyMemberData));
        oldData.splice(key, 1);
        setFamilyMemberData(oldData);
    }

    const handleEditFamilyMember = (data, key) => {
        setFamilyEditKey(key)
        setFamilyMemberInitalval(data)
        setShowModal(true);
    }

    const FamilyMemberComponent = () => {

        const onFamilySubmit = (val) => {
            let oldData = JSON.parse(JSON.stringify(familyMemberData));

            if (familyEditKey !== false) {
                oldData[familyEditKey] = val;
            } else {
                oldData.push(val);
            }
            setFamilyMemberData(oldData);
            setFamilyMemberInitalval(initialFamilyData);
            setFamilyEditKey(false)
            setShowModal(false);
        }

        return (
            <FamilyMemberAdd familyMemberInitalval={familyMemberInitalval} submit={onFamilySubmit} qualificationData={qualificationData} />
        )
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, action) => onFormSubmit(values, action)}
            validationSchema={MembersValidation}
            enableReinitialize
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                setFieldValue
            }) => (
                <Drawer
                    anchor={"right"}
                    open={show}
                    PaperProps={{
                        sx: { width: { xs: '100%', md: '70%', sm: "70%", lg: "70%" } },
                    }}
                    onClose={() => close()}
                >
                    <Grid sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} >
                        <Grid container flex={0} px={1} py={1} borderBottom={1} borderColor={"rgba(5, 5, 5, 0.06)"}>
                            <Grid item alignSelf={"center"}>
                                <IconButtonIcons color="default" title="Close" IconComponent={CloseIcon} onClick={() => close()} />
                            </Grid>
                            <Grid item alignSelf={"center"}>
                                <Typography variant="h6">{formType} Members</Typography>
                            </Grid>
                        </Grid>
                        <Grid flex={1} px={2} py={5} overflow={"auto"}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={12}>
                                    <CommonFileUploadToServer
                                        name="profile"
                                        onChange={(val) => setFieldValue("profile", val)}
                                        value={values?.profile || ""}
                                        label="Profile"
                                        error={Boolean(errors.profile)}
                                        helperText={errors.profile}
                                    />
                                </Grid>
                                {
                                    isAuth().roles === "admin" && isAuth().roles === "members" && (
                                        <Grid item xs={12} md={6}>
                                            <AutoCompleteSelect
                                                label="Type & Select Sanstha *"
                                                name="sanstha_id"
                                                id="sanstha_id"
                                                fullWidth
                                                options={sansthaList !== undefined ? sansthaList : []}
                                                onChange={(e, val) => setFieldValue("sanstha_id", val)}
                                                value={values?.sanstha_id || []}
                                                error={Boolean(errors.sanstha_id)}
                                                helperText={errors.sanstha_id}
                                                disabled={disabled}
                                                isMultiple={true}
                                            />
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='name'
                                        name="name"
                                        label="Name *"
                                        onChange={handleChange("name")}
                                        value={values?.name || ""}
                                        error={Boolean(errors.name)}
                                        helperText={errors.name}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='mobile'
                                        name="mobile"
                                        label="Mobile / Whatsapp No. *"
                                        onChange={handleChange("mobile")}
                                        value={values?.mobile || ""}
                                        error={Boolean(errors.mobile)}
                                        helperText={errors.mobile}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='alternate_no'
                                        name="alternate_no"
                                        label="Alternate Number"
                                        onChange={handleChange("alternate_no")}
                                        value={values?.alternate_no || ""}
                                        error={Boolean(errors.alternate_no)}
                                        helperText={errors.alternate_no}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='email'
                                        name="email"
                                        label="Email"
                                        onChange={handleChange("email")}
                                        value={values?.email || ""}
                                        error={Boolean(errors.email)}
                                        helperText={errors.email}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DatePickerComponent
                                        id={"dob"}
                                        name={"dob"}
                                        label="Date of Birth"
                                        onChange={(val) => setFieldValue("dob", val)}
                                        value={values?.dob ? new Date(values?.dob) : null}
                                        fullWidth
                                        disabled={disabled}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.gender || "male"}
                                        onChange={handleChange("gender")}
                                        displayEmpty
                                        label="Gender"
                                        name="gender"
                                        id="gender"
                                        items={[
                                            { label: "Male", value: "male" },
                                            { label: "Female", value: "female" },
                                        ]}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.gotra || ""}
                                        onChange={handleChange("gotra")}
                                        displayEmpty
                                        label="Gotra"
                                        name="gotra"
                                        id="gotra"
                                        items={gotraData || []}
                                        error={Boolean(errors.gotra)}
                                        helperText={errors.gotra}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.work_status || ""}
                                        onChange={handleChange("work_status")}
                                        displayEmpty
                                        label="Your Work Status"
                                        name="work_status"
                                        id="work_status"
                                        items={workStatus}
                                        error={Boolean(errors.work_status)}
                                        helperText={errors.work_status}
                                        disabled={disabled}
                                    />
                                </Grid>
                                {
                                    values?.work_status === "Business" && (
                                        <Grid item xs={12} md={6}>
                                            <Input
                                                id='business_name'
                                                name="business_name"
                                                label="Your Business Name"
                                                onChange={handleChange("business_name")}
                                                value={values?.business_name || ""}
                                                error={Boolean(errors.business_name)}
                                                helperText={errors.business_name}
                                                fullWidth
                                                disabled={disabled}
                                            />
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.member_status || ""}
                                        onChange={handleChange("member_status")}
                                        displayEmpty
                                        label="Member Status"
                                        name="member_status"
                                        id="member_status"
                                        items={memberStatus}
                                        error={Boolean(errors.member_status)}
                                        helperText={errors.member_status}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.qualification || ""}
                                        onChange={handleChange("qualification")}
                                        displayEmpty
                                        label="Qualification"
                                        name="qualification"
                                        id="qualification"
                                        items={qualificationData || []}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='native_place'
                                        name="native_place"
                                        label="Native Place"
                                        onChange={handleChange("native_place")}
                                        value={values?.native_place || ""}
                                        error={Boolean(errors.native_place)}
                                        helperText={errors.native_place}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DatePickerComponent
                                        id={"marriage_date"}
                                        name={"marriage_date"}
                                        label="Marriage Date"
                                        onChange={(val) => setFieldValue("marriage_date", val)}
                                        value={values?.marriage_date ? new Date(values?.marriage_date) : null}
                                        fullWidth
                                        disabled={disabled}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <StyledTextarea
                                        id='address'
                                        name="address"
                                        minRows={4}
                                        label="Address"
                                        onChange={handleChange("address")}
                                        value={values?.address || ""}
                                        error={Boolean(errors.address)}
                                        helperText={errors.address}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='city'
                                        name="city"
                                        label="City"
                                        onChange={handleChange("city")}
                                        value={values?.city || ""}
                                        error={Boolean(errors.city)}
                                        helperText={errors.city}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='state'
                                        name="state"
                                        label="State"
                                        onChange={handleChange("state")}
                                        value={values?.state || ""}
                                        error={Boolean(errors.state)}
                                        helperText={errors.state}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        id='zipcode'
                                        name="zipcode"
                                        label="Zipcode"
                                        onChange={handleChange("zipcode")}
                                        value={values?.zipcode || ""}
                                        error={Boolean(errors.zipcode)}
                                        helperText={errors.zipcode}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicSelect
                                        size="small"
                                        value={values?.status || "true"}
                                        onChange={handleChange("status")}
                                        displayEmpty
                                        label="Active"
                                        name="status"
                                        id="status"
                                        items={[
                                            { label: "Active", value: "true" },
                                            { label: "In-active", value: "false" },
                                        ]}
                                        error={Boolean(errors.status)}
                                        helperText={errors.status}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <StyledTextarea
                                        id='bio'
                                        name="bio"
                                        minRows={4}
                                        label="Bio"
                                        onChange={handleChange("bio")}
                                        value={values?.bio || ""}
                                        error={Boolean(errors.bio)}
                                        helperText={errors.bio}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Grid item xs={12} md={12} display={"flex"} gap={2} flexDirection={"row"} alignItems={"center"}>
                                        <Typography variant="h6">Family Details</Typography>
                                        <IconButtonIcons disabled={disabled} size="small" color="info" onClick={() => handleAddFamilyMember()} IconComponent={Add} />
                                    </Grid>
                                    {
                                        familyMemberData && familyMemberData.length > 0
                                            ?
                                            <Fragment>
                                                <Grid item xs={12} md={12}>
                                                    <Grid container spacing={2}>
                                                        {
                                                            familyMemberData.map((obj, key) => {
                                                                return (
                                                                    <Grid item xs={3} key={key}>
                                                                        <Card variant="outlined" sx={{ position: "relative" }}>
                                                                            <CardContent>
                                                                                {
                                                                                    obj.relation || obj?.gender ? (
                                                                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                                                            {obj.relation} {obj?.gender ? `(${obj?.gender})` : ""}
                                                                                        </Typography>
                                                                                    ) : null
                                                                                }

                                                                                <Typography variant="h5" component="div">
                                                                                    {obj.name}
                                                                                </Typography>
                                                                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                                                    {obj.dob ? handleDateTimeDefault(obj?.dob) : null}
                                                                                </Typography>
                                                                                <Typography variant="body2">
                                                                                    {obj?.mobile ? <Fragment>Mobile: {obj?.mobile}</Fragment> : null}
                                                                                    {obj?.qualification ? <Fragment><br />Qualification: {obj?.qualification}</Fragment> : null}
                                                                                    {obj?.married !== "" && obj.marriage_date && obj.marriage_date !== "Invalid date" ? <Fragment><br />Marriage Date: {obj?.married ? format(new Date(parseISO(obj.marriage_date)), "dd MMM,yyyy") : ""}</Fragment> : null}
                                                                                    {obj?.blood_group ? <Fragment><br />`Blood Group: ${obj?.blood_group}`</Fragment> : null}
                                                                                    {obj?.note ? <Fragment><br />Note: {obj?.note}</Fragment> : null}
                                                                                </Typography>
                                                                                <Box sx={{ position: "absolute", right: 0, top: 0 }}>
                                                                                    <IconButtonIcons disabled={disabled} color="info" title="Edit" IconComponent={EditOutlined} onClick={() => handleEditFamilyMember(obj, key)} />
                                                                                    <IconButtonIcons disabled={disabled} color="error" title="Delete" IconComponent={DeleteOutline} onClick={() => handleDeleteFamilyMember(obj, key)} />
                                                                                </Box>
                                                                            </CardContent>
                                                                        </Card>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Fragment>
                                            :
                                            null
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            formType !== 'View'
                                ?
                                <Grid flexShrink={0} borderTop={1} borderColor={"rgba(152, 188, 252, 0.16)"} sx={{ padding: "8px 16px" }}>
                                    <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                            <Button size="large" color="warning" type="button" onClick={() => close()}>Cancel</Button>
                                            <Button size="large" type="submit" loading={addMembersLoading || updateMembersLoading} onClick={() => handleSubmit()}>Save</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                null
                        }

                        <CommonModal show={showModal} close={() => setShowModal(false)} title="Add Family Member" child_component={<FamilyMemberComponent />} />
                    </Grid>
                </Drawer>
            )
            }
        </Formik >
    )
}